import ReactDOM, { Root } from "react-dom/client";
import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import { logAnalyticsEventsToConsole } from "@ignite-analytics/track";
import App from "./App";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";

import { setupProxyAuth } from "./setupProxyAuth";
import { env } from "./env";
import { staticRouter } from "./routes/routes";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(localizedFormat);

let root: Root;

window.renderContracts = (containerId, props) => {
    root = ReactDOM.createRoot(document.getElementById(containerId)!);
    root.render(
        <React.StrictMode>
            <App {...props} router={staticRouter} />
        </React.StrictMode>
    );
};

window.unmountContracts = () => root?.unmount();

const devSetup = async () => {
    const localRootId = "root";
    const webProxy = env.REACT_APP_PROXY_WEB_TO_PRODUCTION === "true";

    let sessionContext = {
        id: import.meta.env.VITE_DEFAULT_USER_ID ?? "",
        tenant: import.meta.env.VITE_DEFAULT_TENANT ?? "",
        email: import.meta.env.VITE_DEFAULT_USER ?? "",
    };

    if (webProxy) {
        console.log("Setting up proxy auth");
        sessionContext = await setupProxyAuth();
    }

    if (import.meta.env.MODE === "development") {
        const { msw } = await import("./mocks/browser");
        await msw.start();
    }

    logAnalyticsEventsToConsole();

    if (!window.renderContracts) throw new Error("Missing renderContracts function");
    window.renderContracts(localRootId, { sessionContext, path: "/contracts" });
};

if (process.env.NODE_ENV === "development") {
    devSetup();
}
